export default [
  {
    title: 'Admin',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Clinic',
        icon: 'BriefcaseIcon',
        route: 'view-clinic',
        // acl
        action: 'read',
        resource: 'Clinic',
      },
      {
        title: 'Clinicians',
        icon: 'ClipboardIcon',
        route: 'view-clinicians',
        action: 'read',
        resource: 'Clinic',
      },
      {
        title: 'Patient Assignment',
        route: 'assigning-patients',
        action: 'edit',
        resource: 'Patients',
      },
    ],
  },
]
